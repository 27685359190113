export const DEFAULT_STARTING_DATE =
  process.env.REACT_APP_DEFAULT_AT_START_DATE;
export const BILLING_ANALYTICS_URL =
  process.env.REACT_APP_BILLING_ANALYTICS_URL;

export const REACT_APP_AXIOS_TIMEOUT = parseInt(
  process.env.REACT_APP_AXIOS_TIMEOUT ?? "600000"
);
export const IS_TRAFFIC_AVAILABLE = process.env.REACT_APP_IS_TRAFFIC_AVAILABLE;

export const IS_AGGREGATOR_TRAFFIC_AVAILABLE =
  process.env.REACT_APP_IS_AGGREGATOR_TRAFFIC_AVAILABLE;

export const PUBLIC_KEY =
  process.env.REACT_APP_PUBLIC_KEY;  

  
export const CHARS_USED_STRING = "chars used";
export const STATUS_PENDING = "pending";

export let param_error = "Only alphanumeric characters and underscore are allowed in custom param. Spaces/other special characters are not allowed."
export const single_param_error = "Only one custom param is allowed."
export let TRANS24_VALUE = "Trans24";

export const TEMPLATE_REQ_RULE_RECOMMEND_TO_CARRIER = 1;
export const TEMPLATE_REQ_RULE_APPROVE_BY_DOTGO = 4;
export const TEMPLATE_REQ_RULE_APPROVE_BY_DOTGO_OR_RECOMMEND_TO_CARRIER = 5;

export const isAllowEditAgentInfo = {
  STATUS_BOT_VERIFIED:53,
  STATUS_BOT_VERIFICATION_APPROVED_BY_CARRIER:110,
  LAUNCH_STATUS_SUBMITTED:6,
  STATUS_BOT_APPROVED_BY_CARRIER_PENDING_FROM_RBM :116,
  LAUNCH_STATUS_LAUNCHED:10,
  STATUS_BOT_APPROVED_BY_DOTGO: 31,
  LAUNCH_STATUS_SUSPENDED: 117,
  STATUS_BOT_APPROVED_BY_DOTGO_PENDING_FROM_CARRIER: 42,
  LAUNCH_STATUS_FAILED: 9,
  STATUS_BOT_REJECTED_BY_DOTGO: 32
};
export const ADMIN_DOTGO_READWRITE = 'ADMIN_DOTGO_READWRITE'
export const SUPER_ADMIN_DOTGO = 'SUPER_ADMIN_DOTGO'
export const SUPER_ADMIN_VI_DOTGO = 'SUPER_ADMIN_VI_DOTGO'
export const VI_DOTGO_ADMIN_READWRITE = 'VI_DOTGO_ADMIN_READWRITE'
