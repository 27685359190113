/**
 * @see https://emailregex.com/
 */
export const regexEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
/**
 * Alphanumeric regex
 */
export const regexPasswordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[0-9])(?=.*[.$@!%*?&#^<>()~`+_-])(?=.*[a-z])/;
export const regexURIPattern = /^((https?|ftp|smtp|http):\/\/)?(www.)?[a-z0-9]+[a-z0-9-_]*\.[a-z]+(\/[a-zA-Z0-9#]+\.[a-zA-Z]+\/?)*$/
export const regexZipCodePattern = /^[a-zA-Z]+$/;

export const regexWhiteSpace = /\s/g;
export const regexNonWordChar = /[^\w\s]/gi;
export const regexFirstAndLastName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const regexURL =  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
export const regexOnlyNo =[0-9]
export const imageUrlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const templateNamePattern = /^([a-zA-Z0-9_]+\s)*[a-zA-Z0-9_]+$/;
// export const templateNamePattern = /^([a-zA-Z_])+\s*[a-zA-Z_]+$/;
// export const template_nm = /[a-zA-Z]/;
export const template_nm =/^[a-zA-Z0-9_]+$/
// export const websiteRegex = /((https+):\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,})/
export const regexIPV4 = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
export const latitudeRegex = /^-?([1-8]?[0-9](\.\d{1,7})?|90(\.0+)?)$/;
export const longitudeRegex = /^[-+]?((1[0-7]\d(\.\d{1,7})?|180(\.0{1,7})?)|(\d{1,2}(\.\d{1,7})?))$/; // no validation
export const addVariablePatern =/^[a-zA-Z0-9_]+$/
export const customParamsRegex = /[[\]]/;
export const validCustomParamRegex =  /^\[([a-zA-Z0-9_]+)\]$/;
export const multipleCustomParamRegex = /^\[([a-zA-Z0-9_]+)\](\[([a-zA-Z0-9_]+)\])*$/
export const singleCustomParamRegex =  /\[[^\]]+\]/g
export const urlMatchRegex = /^([^[\]]+)\//;
